
  <template>
  <div class="homepage">
    <van-form class="formStyle">
      <div class="form-news">
        <div class="news-box">
          <div class="news-tip">手机号：</div>
          <div class="news-content">
            <input type="text" v-model="mobile" />
          </div>
        </div>
        <div class="news-box">
          <div class="news-tip">交易凭证号：</div>
          <div class="news-content news-content1">
            <input type="text" v-model="tradeno" />
          </div>
          <div class="new-scan" @click="toScan"><van-icon name="scan" /></div>
        </div>
        <div class="news-box">
          <div class="news-tip">交易金额：</div>
          <div class="news-content">
            <input type="number" v-model="amount" step="0.01" />
          </div>
        </div>
      </div>

      <div>
        <div style="margin: 16px">
          <van-button block color="#428bca" @click="BillDetail">
            账单详情
          </van-button>
        </div>
      </div>
    </van-form>

    <van-overlay :show="show" @click="closeScan">
      <div class="wrapper">
        <video id="video"></video>
      </div>
    </van-overlay>
  </div>
</template>


<script>
import { BrowserMultiFormatReader } from '@zxing/library'
import utils from '../assets/utils'
import Event from '../util/event.js'
export default {
  data() {
    return {
      mobile: '',
      tradeno: '',
      amount: '0.00',
      codeReader: new BrowserMultiFormatReader(),
      show: false
    }
  },
  created() {
    this.mobile = localStorage.getItem('mobile')
    this.tradeno = utils.getUrlKey('tradeno')
    this.amount = utils.getUrlKey('amount')
  },
  mounted() {
    let that = this
    Event.$on('refreshEvent', function () {
      that.toScan()
    })
  },
  methods: {
    BillDetail() {
      
      if (this.mobile ) {
        if (this.tradeno) {
          if (this.amount && this.amount >= 0) {
            localStorage.setItem('mobile', this.mobile)
            this.$axios
              .get(
                `outbill?mobile=${this.mobile}&tradeno=${this.tradeno}&amount=${this.amount}`
              )
              .then((res) => {
                if (res.data.retcode == '0000') {
                  this.$router.push({
                    path: '/billdetail',
                    query: {
                      tradeno: this.tradeno,
                      amount: this.amount
                    }
                  })
                } else {
                  this.$dialog
                    .alert({
                      message: res.data.retmessage
                    })
                    .then(() => {
                      // on close
                    })
                }
              })
          } else {
            this.$dialog
              .alert({
                message: '请输入交易金额，金额不能小于0'
              })
              .then(() => {
                // on close
              })
          }
        } else {
          this.$dialog
            .alert({
              message: '请输入交易凭证号'
            })
            .then(() => {
              // on close
            })
        }
      } else {
        this.$dialog
          .alert({
            message: '请输入正确的11位电话号码'
          })
          .then(() => {
            // on close
          })
      }
    },
    toScan() {
      // Event.$emit('refreshEvent')
      this.show = true
      this.codeReader
        .listVideoInputDevices()
        .then((videoInputDevices) => {
          // 默认获取第一个摄像头设备id
          let firstDeviceId = videoInputDevices[0].deviceId
          // 获取第一个摄像头设备的名称
          const videoInputDeviceslablestr = JSON.stringify(
            videoInputDevices[0].label
          )
          if (videoInputDevices.length > 1) {
            // 判断是否后置摄像头
            if (videoInputDeviceslablestr.indexOf('back') > -1) {
              firstDeviceId = videoInputDevices[0].deviceId
            } else {
              firstDeviceId = videoInputDevices[1].deviceId
            }
          }
          this.decodeFromInputVideoFunc(firstDeviceId)
        })
        .catch((err) => {
          alert(err)
        })
    },
    decodeFromInputVideoFunc(firstDeviceId) {
      this.tradeno = null // 重置
      this.codeReader.decodeFromVideoDevice(
        firstDeviceId,
        'video',
        (result, err) => {
          if (result) {
            ;(this.tradeno = result.text), (this.show = false)
            this.codeReader.reset() // 重置
          }
        }
      )
    },
    closeScan() {
      this.show = false
      this.codeReader.reset() // 重置
    }
  },
  beforeDestroy() {
    localStorage.setItem('mobile', this.mobile)
  }
}
</script>
<style scoped>
.homepage .form-news {
  padding: 0 15px;
  height: 50%;
  padding-top: 100px;
}
.homepage .form-news .news-box {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  line-height: 30px;
}
.homepage .news-box .news-tip {
  width: 26%;
  padding: 0 15px;
}
.homepage .news-box .news-content {
  width: 55%;
  border: 1px solid #ccc;
  padding: 0 10px;
}
.homepage .news-box .news-content1 {
  width: 48%;
}
.homepage .news-content input {
  border: none;
  width: 100%;
}
.homepage .news-box .new-scan {
  border: 1px solid #ccc;
  width: 6.5%;
  text-align: center;
}
.homepage .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.homepage .van-button--block {
  border-radius: 5px;
}
</style>